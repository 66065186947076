import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
    private counter: number =0;
    isLoading = new Subject<number>();

    constructor(){
    }

    show() {
      this.counter++;
      this.isLoading.next(this.counter);
    }
    hide() {
      this.counter--;
      this.isLoading.next(this.counter);
    }
}
