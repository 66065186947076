<div class="main-container">
  <div class="inside-main-container">
      <mat-accordion >
        <mat-expansion-panel class="mat-elevation-z8" [(expanded)]="isExpanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>{{ 'add' | translate }} {{ 'firmware' | translate }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h6>{{ 'add_firmware_string' | translate }}</h6>
          <div class="example-container">
            <form [formGroup]="form" id="ngForm" (ngSubmit)="addFirmware()">
              <button type="button" mat-raised-button (click)="fileInput.click()">{{ 'choose_file' | translate}}</button>
              <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file">
              <span class="file-name">{{selectedFile?.name}}</span>
              <mat-form-field>
                <input matInput placeholder="{{ 'name' | translate}}" required formControlName="fir_Name">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="{{ 'description' | translate}}" formControlName="fir_Description">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="{{ 'version' | translate}}" required formControlName="fir_Version">
              </mat-form-field>
              <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="{{ 'release_date' | translate}}" formControlName="fir_ReleaseDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <mat-select placeholder="{{ 'machine_type' | translate}}" required formControlName="fir_MachineType">
                  <mat-option [value]="6">TWIST</mat-option>
                  <mat-option [value]="4">SOFT-TOUCH</mat-option>
                  <mat-option [value]="14">LITE</mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-raised-button color="primary" [disabled]="!form.valid" form="ngForm" type="submit">{{ 'add' | translate }} {{ 'firmware' | translate }}</button>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    <br/>
    <mat-card class="mat-elevation-z8">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate}}...">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort >

          <ng-container matColumnDef="fiR_Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'firmware' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.fiR_Name}} </td>
          </ng-container>

          <ng-container matColumnDef="fiR_Description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'description' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.fiR_Description}} </td>
          </ng-container>

          <ng-container matColumnDef="fiR_Version">
            <th mat-header-cell *matHeaderCellDef> {{ 'version' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.fiR_Version}} </td>
          </ng-container>

          <ng-container matColumnDef="fiR_ReleaseDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'release_date' | translate }} </th>
            <td mat-cell *matCellDef="let element">{{element.fiR_ReleaseDate | date:'dd/MM/yyyy'}}</td>
          </ng-container>

          <ng-container matColumnDef="fiR_MachineType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'machine_type' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <div [ngSwitch]="element.fiR_MOD_Id"></div>
              <label *ngSwitchCase="6">TWIST</label>
              <label *ngSwitchCase="4">SOFT-TOUCH</label>
              <label *ngSwitchCase="14">LITE</label>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button class="mr-10" (click)="downloadFile(element)" color="primary">{{ 'download' | translate }}</button>

              <button mat-raised-button (click)="deleteFirmware(element)" color="warn">{{ 'delete' | translate }}</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1000]" showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>
</div>
