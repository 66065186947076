import { Injectable } from '@angular/core';
import { ConfigUtil } from '../utils/configUtils';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { UserLogged } from '../objects/user-logged';
import { MacMacchine } from '../objects/macMacchine';
import { CredentialSession } from '../objects/userSession';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public currentUserSubject: BehaviorSubject<UserLogged>;
  public currentCredentialSubject: BehaviorSubject<CredentialSession>;
  public currentMachineSubject : BehaviorSubject<MacMacchine>;

  constructor(
  ) 
  { 
    this.currentMachineSubject = new BehaviorSubject<MacMacchine>(JSON.parse(localStorage.getItem(ConfigUtil.machineSessioned)));
    this.currentCredentialSubject = new BehaviorSubject<CredentialSession>(JSON.parse(localStorage.getItem(ConfigUtil.credentialSessioned)));
    this.currentUserSubject = new BehaviorSubject<UserLogged>(JSON.parse(localStorage.getItem(ConfigUtil.userSessioned)));
  }

  public get currentUserValue(): UserLogged {
    return this.currentUserSubject.value;
  }
  
  public get currentCredentialValue(): CredentialSession {
    return this.currentCredentialSubject.value;
  }

  public get currentMachineValue(): MacMacchine {
    return this.currentMachineSubject.value;
  }

  setLoginSession(us: UserLogged){
    if(us!=null){
      localStorage.setItem(ConfigUtil.userSessioned, JSON.stringify(us));
      this.currentUserSubject.next(us);
    }
    else{
      localStorage.removeItem(ConfigUtil.userSessioned);
      this.currentUserSubject.next(null);
    }
  }

  setCredentialSession(credential:CredentialSession){
    if(credential!=null){
      localStorage.setItem(ConfigUtil.credentialSessioned, JSON.stringify(credential));
      this.currentCredentialSubject.next(credential);
    }
    else{
      localStorage.removeItem(ConfigUtil.credentialSessioned);
      this.currentCredentialSubject.next(null);
    }
  }

  setMachineSession(mac:MacMacchine){
    if(mac!=null){
      localStorage.setItem(ConfigUtil.machineSessioned, JSON.stringify(mac));
      this.currentMachineSubject.next(mac);
    }
    else{
      localStorage.removeItem(ConfigUtil.machineSessioned);
      this.currentMachineSubject.next(null);
    }
  }

  setGenericSession(value: any, key: string){
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeGenericSession(key: string){
    localStorage.removeItem(key);
  }

  getGenericSession(key: string): string{
    return localStorage.getItem(key);    
  }
}
