import { IModelObj } from './iModelObj';
import { RgsRegistrazione } from './rgsRegistrazione';
  
export interface IMacMacchine extends IModelObj {
    maC_Id: number;
    maC_Numero_Serie_MS: number;
    maC_Matricola: string;
    maC_BloccoSx: number;
    maC_BloccoDx: number;
    maC_VersSwInt: string;
    maC_VersSwQSx: string;
    maC_VersSwVSx: string;
    maC_ModelloSx: number;
    maC_VersSwQDx: string;
    maC_VersSwVDx: string;
    maC_ModelloDx: number;
    maC_TelefonoSim?: string;
    maC_Note?: string;
    maC_IdMessaggio?: number;
    maC_Indirizzo: string;
    maC_AltaTemp: number;
    maC_ReteWifi: number;
    maC_Macchina: number;
    maC_LastUpdate?: string;
    maC_Pagamento?: number;
    maC_VersSwQCen: string;
    maC_VersSwVCen: string;
    maC_ModelloCen?: number;
    maC_BloccoCen?: number;
    maC_Grammi?: number; 
    rgS_Registrazione: RgsRegistrazione;
    saL_StoricoAllarmi: any[];
    scO_StoricoComandi: any[];
    smO_StoricoModifiche: any[];   
    //custom objects
    maC_ModelString: string;
    
  }
  

  export class MacMacchine {
    maC_Id: number;
    maC_Numero_Serie_MS: number;
    maC_Matricola: string;
    maC_BloccoSx: number;
    maC_BloccoDx: number;
    maC_VersSwInt: string;
    maC_VersSwQSx: string;
    maC_VersSwVSx: string;
    maC_ModelloSx: number;
    maC_VersSwQDx: string;
    maC_VersSwVDx: string;
    maC_ModelloDx: number;
    maC_TelefonoSim?: string;
    maC_Note?: string;
    maC_IdGestore: number;
    maC_IdMessaggio?: number;
    maC_Indirizzo: string;
    maC_AltaTemp: number;
    maC_ReteWifi: number;
    maC_Macchina: number;
    maC_LastUpdate?: string;
    maC_Pagamento?: number;
    maC_VersSwQCen: string;
    maC_VersSwVCen: string;
    maC_ModelloCen?: number;
    maC_BloccoCen?: number;
    maC_Grammi?: number; 
    rgS_Registrazione: RgsRegistrazione;
    saL_StoricoAllarmi: any[];
    scO_StoricoComandi: any[];
    smO_StoricoModifiche: any[];   
    //custom objects
    maC_ModelString: string;
    id: string | number;

    constructor(props?: IMacMacchine) {
      if(props!=undefined){
          Object.keys(props).forEach(prop => {
              const value = props[prop];
              this[prop] = value;
          });
      }
      this.id = props && props.maC_Numero_Serie_MS|| 0;
    }
  }

